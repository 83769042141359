import React from 'react';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { v4 } from "uuid";
var assert = require('assert');
const uuidv4 = v4;

const getRowsVisible = (props) => {
    let retVal;
    switch (props.ViewChild.viewSpec.Format) {
        case 'Table':
            retVal = getFromCollection(props);
            if (props.ViewChild.viewSpec.Label === 'Distributions') {
                let dataLocal = props.ViewChild.context.Controller.tracks[0].viewItem.alternatives[0];
                props.ViewChild.specialData = {
                    Funds: dataLocal.Elems[0] != null ? dataLocal.Elems[0].collection : [],
                    Accounts: props.ViewChild.collection != null ? props.ViewChild.collection : []
                }
                //props.ViewChild.specialData.Accounts.
                nachaReady = true;
            }
            if (retVal != null) {
                if (props.ViewChild.criteriaStatus !== 'Same') {
                    props.ViewChild.criteriaStatus = 'Changed';
                    window.controller.alertFromView('TO_VIEW',
                        'CriteriaChanged', 
                        props.ViewChild.ModelPath != null ? [...props.ViewChild.ModelPath]: [...props.ViewChild.modelPath], 
                        props.ViewChild.ViewPath != null ? [...props.ViewChild.ViewPath]: [...props.ViewChild.viewPath], 
                        null,
                        props.ViewChild
                    );
                } else {
                    let embeddedElement = props.ViewChild.viewSpec.Table.Elems.find(cur => cur.ElemType === 'Embedded');
                    if (embeddedElement != null) {
                        if (props.ViewChild.Items == null) {
                            props.ViewChild.Items = {};
                        }
                        retVal.forEach(itemCur => {
                            if (props.ViewChild.Items[itemCur.key] == null) {
                                props.ViewChild.Items[itemCur.key] = {Watching: false, RowView: {ExtensionPath: [], Elems: []}};
                            }
                            let distributionElement = props.ViewChild.viewSpec.Table.Elems.find(cur => cur.Label === 'Distribution');
                            if (distributionElement != null) {
                                props.ViewChild.Items[itemCur.key].RowView.Elems.push({
                                    LabelExtended: distributionElement.Label,
                                    LabelFlattened: distributionElement.Label,
                                    SpecElem: distributionElement
                                });
                                calculatedValue(distributionElement, props.ViewChild.Items[itemCur.key], itemCur);
                            }
                        });
                    } else {
                        if (props.ViewChild.LabelFlattened === 'Confirmations') {
                            retVal.forEach(itemCur => {
                                //itemCur.model.Attrs['DateExt'].Value = '';
                                if ((itemCur.model.Attrs['DateText'].Value == null || itemCur.model.Attrs['DateText'].Value === '' || itemCur.model.Attrs['DateText'].Value.includes("F")) 
                                        && itemCur.model.Attrs['AccountNumber'].Value != null) {
                                    if (props.ViewChild.context != null && props.ViewChild.context.ViewItem != null && props.ViewChild.context.ViewItem.active != null && props.ViewChild.context.ViewItem.active != null
                                             && props.ViewChild.context.ViewItem.active.Elems != null && props.ViewChild.context.ViewItem.active.Elems[0].collection != null) {
                                        let collItemFound = props.ViewChild.context.ViewItem.active.Elems[0].collection.find(cur => cur.key === itemCur.model.Attrs['AccountNumber'].Value);
                                        if (collItemFound != null) {
                                            itemCur.model.Attrs['DateText'].Value = collItemFound.active.Elems[3].Value;
                                        }
                                    }
                                }
                            });
                        }
                    }
                }
            }
            if (props.ViewChild.Sorting == null) {
                props.ViewChild.Sorting = [];
                props.ViewChild.viewSpec.Table.Elems.forEach((elemCur) => {
                    if (elemCur.Sortable != null) {
                        let sortingSeg = {
                            ElemSpec: {...elemCur},
                            Dir: elemCur.Sortable.Dir,
                            Comparison: elemCur.Sortable.Comparison,
                            Order: elemCur.Sortable.Order != null ? elemCur.Sortable.Order : 999
                        };
                        props.ViewChild.Sorting.push({...sortingSeg});
                    }
                });
            }
            props.ViewChild.Sorting.sort((a,b) => {return a.Order - b.Order});
            if (props.ViewChild.Filtering == null) {
                props.ViewChild.Filtering = [];
                props.ViewChild.viewSpec.Table.Elems.forEach((elemCur) => {
                    if (elemCur.Filter != null) {
                        let filteringElem = {
                            ElemSpec: {...elemCur},
                            Selections: elemCur.Filter.DefaultSelections != null ? JSON.parse(JSON.stringify(elemCur.Filter.DefaultSelections)) : [],
                            AnchorEl: null
                        };
                        props.ViewChild.Filtering.push({...filteringElem});
                    }
                });
            }
            break;
        default:
            break;
    }
    return retVal;
}

const getFromCollection = (props) => {
    let retVal = [];
    let retData = props.ViewChild.collection;
    if (retData != null) {
        if ((props.ViewChild.searchText != null && props.ViewChild.searchText >'') || (props.ViewChild.Filtering != null && props.ViewChild.Filtering.length > 0)) {
            let retVal1 = [...retData]; 
            let retVal2 = [];
            if (props.ViewChild.searchText != null) {
                retVal1.forEach(itemCur => {
                    for (let attrCur in itemCur.model.Attrs) {
                        let attrDetail = itemCur.model.Attrs[attrCur];
                        if (attrDetail.Type === 'P' && attrDetail.Value != null) {
                            if (attrDetail.Value.toString().toLowerCase().includes(props.ViewChild.searchText.toString().toLowerCase()) === true) {
                                retVal2.push(itemCur);
                                break;
                            }
                        }
                        if (attrDetail.Type === 'R' && attrDetail.Value != null && attrCur === 'Fund' ) {
							if (attrDetail.Value === '1') {
								attrDetail.Value = '001';
							}
                            let valueFound = DefaultSelections.find(cur => cur.Id === attrDetail.Value);
                            if (valueFound != null && valueFound.Value != null &&
                                    valueFound.Value.toString().toLowerCase().includes(props.ViewChild.searchText.toString().toLowerCase()) === true) {
                                retVal2.push(itemCur);
                                break;
                            }
                        }
                    }
                });
                
                //retVal = retVal2;
            } else {
                retVal2 = retVal1;
            }
            if (props.ViewChild.Filtering != null && props.ViewChild.Filtering.length > 0) {
                props.ViewChild.Filtering.forEach(filteringElemCur => {
                    filteringElemCur.Selections.forEach(selectionCur => {
                        selectionCur.Count = 0;
                    });
                });
                retVal2.forEach(itemCur => {
                    for (let attrCur in itemCur.model.Attrs) {
                        let attrDetail = itemCur.model.Attrs[attrCur];
                        
                        if (attrDetail.Type === 'R' && attrDetail.Value != null && attrCur === 'Fund' ) {
							if (attrDetail.Value === '1') {
								attrDetail.Value = '001';
							}
						}
                        
                        let filteringElemCur = props.ViewChild.Filtering.find(cur => cur.ElemSpec.ModelAttr === attrCur);
                        if (filteringElemCur != null && attrDetail.Value != null) {
                            let selectionCur = filteringElemCur.Selections.find(cur => cur.Id.toLowerCase() === attrDetail.Value.toString().toLowerCase());
                            if (selectionCur != null ) {
                                selectionCur.Count++;
                                if (selectionCur.Selected === true) {
                                    retVal.push(itemCur);
                                }
                                break;
                            }
                        }
                    }
                });
            } else {
                retVal = retVal2;
            }
        } else {
            retVal = [...retData]; 
        }

    /**/

        if (props.ViewChild.Sorting != null) {
            retVal.sort((a,b) => {
                for (let sortingSegCur = 0; sortingSegCur < props.ViewChild.Sorting.length; sortingSegCur++) {
                    let sortingSegDetail = props.ViewChild.Sorting[sortingSegCur];
                    let elemSpecCur = props.ViewChild.Sorting[sortingSegCur].ElemSpec;
                    let aValue;
                    let bValue;

                    if (sortingSegDetail.Comparison === 'amount') {
                        aValue = a.model.Attrs != null && a.model.Attrs[elemSpecCur.ModelAttr] != null && a.model.Attrs[elemSpecCur.ModelAttr].Value != null ? a.model.Attrs[elemSpecCur.ModelAttr].Value : '';
                        bValue = b.model.Attrs != null && b.model.Attrs[elemSpecCur.ModelAttr] != null && b.model.Attrs[elemSpecCur.ModelAttr].Value != null ? b.model.Attrs[elemSpecCur.ModelAttr].Value : '';
                        aValue = parseFloat(aValue);
                        bValue = parseFloat(bValue);
                    } else {
                        aValue = a.model.Attrs != null && a.model.Attrs[elemSpecCur.ModelAttr] != null && a.model.Attrs[elemSpecCur.ModelAttr].Value != null ? a.model.Attrs[elemSpecCur.ModelAttr].Value.toLowerCase() : '';
                        bValue = b.model.Attrs != null && b.model.Attrs[elemSpecCur.ModelAttr] != null && b.model.Attrs[elemSpecCur.ModelAttr].Value != null ? b.model.Attrs[elemSpecCur.ModelAttr].Value.toLowerCase() : '';
                    }
                    
                    if (sortingSegDetail.Comparison === 'date' && aValue.includes('/') === true) {
                        let aSplit = aValue.split('/');
                        let bSplit = bValue.split('/');
                        aValue = aSplit[2]+'-'+aSplit[0].padStart(2, '0')+'-'+aSplit[1].padStart(2, '0');
                        bValue = bSplit[2]+'-'+bSplit[0].padStart(2, '0')+'-'+bSplit[1].padStart(2, '0');
                    }
                    
                    if (sortingSegDetail.Dir === 'asc') {
                        if (aValue > bValue) {
                            return 1;
                        } else {
                            if (aValue < bValue) {
                                return -1;
                            }
                        }
                    } else {
                        if (aValue > bValue) {
                            return -1;
                        } else {
                            if (aValue < bValue) {
                                return 1;
                            }
                        }
                    }
                }
                return 0;
            });
        }
    /**/
    }
    return retVal;
}

let nachaReady = false;

const DefaultSelections = [
    {Id: '001', Value: 'Emerson Equity Bridge Fund I, LLC', Selected: true},
    {Id: '101', Value: 'IHC - Capital Fund', Selected: true},
    {Id: '102', Value: 'IHC - Income Fund 1', Selected: false, Memo: '(Closed)'},
    {Id: '103', Value: 'IHC - Income Fund 2', Selected: true},
    {Id: '104', Value: 'IHC - Ashbrook DST', Selected: true},
    {Id: '105', Value: 'IHC - Peachtree DST', Selected: true},
    {Id: '106', Value: 'IHC - Las Vegas DST', Selected: true},
    {Id: '107', Value: 'IHC - Candle Light Cove DST', Selected: true},
    {Id: '108', Value: 'IHC - Athens DST', Selected: true},
    {Id: '109', Value: 'IHC - Income Fund 3', Selected: true},
    {Id: '110', Value: 'IHC - Brookhaven DST', Selected: true},
    {Id: '111', Value: 'IHC - Carson Valley DST', Selected: true},
    {Id: '112', Value: 'IHC - Arlington Heights DST', Selected: true},
    {Id: '113', Value: 'IHC - Reno DST', Selected: true},
    {Id: '114', Value: 'IHC - Naperville DST', Selected: true},
    {Id: '115', Value: 'IHC - Hamilton DST', Selected: true},
    {Id: '116', Value: 'IHC - Appleton DST', Selected: true},
    {Id: '117', Value: 'IHC - Chesterfield', Selected: true},
    {Id: '118', Value: 'IHC - Lake Orion', Selected: true},
    {Id: '119', Value: 'IHC - Largo', Selected: true},
    {Id: '120', Value: 'IHC - Pinellas Park', Selected: true},
    {Id: '121', Value: 'IHC - Dunedin', Selected: true},
    {Id: '122', Value: 'IHC - Fort Myers', Selected: true},
    {Id: '123', Value: 'IHC - Melbourne', Selected: true},
    {Id: '124', Value: 'IHC - Grapevine', Selected: true},
    {Id: '125', Value: 'IHC - Augusta', Selected: true},
    {Id: '126', Value: 'IHC - Round Rock', Selected: true},
	{Id: '127', Value: 'IHC - Delray Beach', Selected: true},
	{Id: '128', Value: 'IHC - Creswell Development', Selected: true},
	{Id: '129', Value: 'IHC - St. Petersburg', Selected: true},
	{Id: '130', Value: 'IHC - Mequon', Selected: true},
	{Id: '131', Value: 'IHC - Fund 5', Selected: true},
	{Id: '132', Value: 'IHC - Eugene DST', Selected: true},
	{Id: '133', Value: 'IHC - North Haven DST', Selected: true},
	{Id: '134', Value: 'IHC - Winery Lane Development DST', Selected: true},
	{Id: '135', Value: 'IHC - Dartmouth DST', Selected: true},
	{Id: '136', Value: 'IHC - Fund 5 Notes', Selected: true},
	{Id: '137', Value: 'IHC - San Marcos DST', Selected: true},
	{Id: '138', Value: 'IHC - New Braunfels DST', Selected: true},
	{Id: '139', Value: 'IHC - Development Fund lll, LLC', Selected: true}
];

const Child = (props) => {
    const classes = useStyles();
    let rowsVisible = getRowsVisible(props);
    const [openNacha, setOpenNacha] = React.useState(false);
    const handleClickOpenNacha = () => {
        setOpenNacha(true);
    };
    const handleCloseNacha = () => {
        setOpenNacha(false);
    };
    return (
        <div> { rowsVisible != null && props.ViewChild.viewSpec.Format === 'Table' ?
            <div>
                { props.ViewChild.viewSpec.Table.SearchBox != null ?
                    <TextField
                        label={props.ViewChild.viewSpec.Table.SearchBox.Prompt}
                        fullwidth={false}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            type: 'search',
                            endAdornment: (
                                <InputAdornment>
                                    <IconButton>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}                                
                        style={{width: '45em'}}
                        defaultValue={props.ViewChild.searchText != null ? props.ViewChild.searchText : ''}
                        onChange={e => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            props.ViewChild.searchText = e.target.value;
                            //window.controller.alertFromView('TO_VIEW');
                            window.controller.alertFromView('TO_VIEW',
                                'ReRender', 
                                [...props.ViewChild.modelPath], 
                                [...props.ViewChild.viewPath],
                                props.ViewChild.collection,
                                props.ViewChild
                            );
                        }}
                    />
                :
                    ''
                }

                <Toolbar>
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        {props.ViewChild.viewSpec.Label+' ('+ rowsVisible.length + ')'}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        { props.ViewChild.viewSpec.Table.ExportExcel != null ?
                            <a href={"data:text/csv;charset=utf-8,%EF%BB%BF" + CSVContent(props, rowsVisible)} download="downloaded.csv"
                                onClick={e => {
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                }} 
                            >
                                {props.ViewChild.viewSpec.Table.ExportExcel.Label}
                            </a>
                        :
                            ''
                        }
                        { props.ViewChild.viewSpec.Label === 'Distributions' && nachaReady === true ?
                            <div>
                            <Button variant="outlined" onClick={handleClickOpenNacha}>
                                Create Nacha File
                            </Button>
                            { openNacha === true ?
                                <Dialog
                                    open={openNacha}
                                    onClose={handleCloseNacha}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    {   props.ViewChild.specialData.Funds.map((fundCur, fundIndex) => 
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    <a href={"data:text/plain;charset=utf-8," + NachaContent(props, fundCur, fundIndex, props.ViewChild.specialData.Accounts)} download="downloaded.txt"
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            e.nativeEvent.stopImmediatePropagation();
                                                        }} 
                                                    >
                                                        {fundCur.model.Attrs.Id.Value + ' - ' +fundCur.model.Attrs.Name.Value}
                                                    </a>
                                                </DialogContentText>
                                            </DialogContent>
                                        )
                                    }
                                    <DialogActions>
                                        <Button onClick={handleCloseNacha} autoFocus>
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            :
                                ''
                            }
                            </div>
                        :
                            ''
                        }
                    </Typography>
                    {props.ViewChild.viewSpec.Insertable != null && props.ViewChild.viewSpec.Insertable === true ? (
                        <Tooltip title={props.ViewChild.viewSpec.InsertLabel}>
                            <IconButton 
                                aria-label={props.ViewChild.viewSpec.Label}
                                onClick={e => {
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                    if (props.ViewChild.viewSpec.InsertForm != null) {
                                        if (props.ViewChild.Items == null) {
                                            props.ViewChild.Items = {};
                                        }
                                        let modelNew = {
                                            key: uuidv4(),
                                        };
                                        window.controller.alertFromView('TO_VIEW',
                                            'CrumbDropped', 
                                            props.ViewChild.ModelPath != null ? [...props.ViewChild.ModelPath] : [...props.ViewChild.modelPath], 
                                            props.ViewChild.ViewPath != null ? [...props.ViewChild.ViewPath] : [...props.ViewChild.viewPath], 
                                            modelNew,
                                            props.ViewChild
                                        );
                                    }
                                }} 
                            >
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Tooltip>
                        ) 
                    : 
                        null
                    }
                </Toolbar>                

                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow key={0}>
                            {   props.ViewChild.viewSpec.Table.Elems.map((elemCur, elemIndex) =>
                                <TableCell className={classes.tableHeader} key={elemIndex}>
                                    { props.ViewChild.Sorting != null && props.ViewChild.Sorting.find(cur => cur.ElemSpec.Label === elemCur.Label) != null ?
                                        <TableSortLabel
                                            active={true}
                                            direction={props.ViewChild.Sorting.find(cur => cur.ElemSpec.Label === elemCur.Label).Dir}
                                            onClick={() => {
                                                    let sortingSeg = props.ViewChild.Sorting.find(cur => cur.ElemSpec.Label === elemCur.Label);
                                                    if (sortingSeg.Dir === 'asc') {
                                                        sortingSeg.Dir = 'desc';
                                                    } else {
                                                        sortingSeg.Dir = 'asc';
                                                    }
                                                    sortingSeg.Order = 0;
                                                    props.ViewChild.Sorting.forEach(cur => cur.Order++);

                                                    props.ViewChild.Sorting.sort((a,b) => {return a.Order - b.Order});
                                                    
                                                    window.controller.alertFromView('TO_VIEW',
                                                        'ReRender', 
                                                        [...props.ViewChild.modelPath], 
                                                        [...props.ViewChild.viewPath],
                                                        props.ViewChild.collection,
                                                        props.ViewChild
                                                    );
                                                }
                                            }
                                        >
                                            {elemCur.Label}
                                        </TableSortLabel>    
                                    :
                                    props.ViewChild.Filtering != null && props.ViewChild.Filtering.find(cur => cur.ElemSpec.Label === elemCur.Label) != null ?
                                        <div>
                                            <Button
                                                aria-controls="customized-menu"
                                                aria-haspopup="true"
                                                variant="contained"
                                                color="default"
                                                onClick={(e) => {
                                                    props.ViewChild.Filtering.find(cur => cur.ElemSpec.Label === elemCur.Label).AnchorEl = e.currentTarget;
                                                    window.controller.alertFromView('TO_VIEW',
                                                        'ReRender', 
                                                        [...props.ViewChild.modelPath], 
                                                        [...props.ViewChild.viewPath],
                                                        props.ViewChild.collection,
                                                        props.ViewChild
                                                    );
                                                }}
                                            >
                                                {elemCur.Label}
                                            </Button>
                                            <StyledMenu
                                                id="customized-menu"
                                                anchorEl={props.ViewChild.Filtering.find(cur => cur.ElemSpec.Label === elemCur.Label).AnchorEl}
                                                keepMounted
                                                open={Boolean(props.ViewChild.Filtering.find(cur => cur.ElemSpec.Label === elemCur.Label).AnchorEl)}
                                                onClose={() => {
                                                    props.ViewChild.Filtering.find(cur => cur.ElemSpec.Label === elemCur.Label).AnchorEl = null;
                                                    window.controller.alertFromView('TO_VIEW',
                                                        'ReRender', 
                                                        [...props.ViewChild.modelPath], 
                                                        [...props.ViewChild.viewPath],
                                                        props.ViewChild.collection,
                                                        props.ViewChild
                                                    );
                                                }}
                                            >
                                                { props.ViewChild.Filtering.find(cur => cur.ElemSpec.Label === elemCur.Label).Selections.map((selectionCur, selectionIndex) =>
                                                    <StyledMenuItem key={selectionIndex} >
                                                        <Checkbox
                                                            key={0}
                                                            checked={selectionCur.Selected}
                                                            onClick={() => {
                                                                selectionCur.Selected = !selectionCur.Selected;
                                                                window.controller.alertFromView('TO_VIEW',
                                                                    'ReRender', 
                                                                    [...props.ViewChild.modelPath], 
                                                                    [...props.ViewChild.viewPath],
                                                                    props.ViewChild.collection,
                                                                    props.ViewChild
                                                                );
                                                            }}
                                                        />
                                                        {selectionCur.Value + (selectionCur.Memo != null ? (' ' + selectionCur.Memo) : '')}
                                                        &nbsp;(<b>{selectionCur.Count}</b>)
                                                    </StyledMenuItem>
                                                                                            
                                                )}
                                            </StyledMenu>
                                          
                                        </div>
                                    :
                                        <span>{elemCur.Label}</span>
                                    }
                                </TableCell>
                            )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {   rowsVisible.map((itemCur, itemIndex) =>
                            <TableRow 
                                key={itemIndex}
                                hover
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                    if (props.ViewChild.viewSpec.Table.Drilldown != null) {
                                        console.log("itemCur: ", itemCur);
                                        window.controller.alertFromView('TO_VIEW',
                                            'CrumbDropped', 
                                            props.ViewChild.ModelPath != null ? [...props.ViewChild.ModelPath] : [...props.ViewChild.modelPath], 
                                            props.ViewChild.ViewPath != null ? [...props.ViewChild.ViewPath] : [...props.ViewChild.viewPath], 
                                            itemCur,
                                            props.ViewChild
                                        );
                                    }
                                }}
                            >
                            {   props.ViewChild.viewSpec.Table.Elems.map((elemCur, elemIndex) =>
                                    <TableCell align="left" key={elemIndex} style={{ verticalAlign: 'top' }}>
                                        { itemCur.model.Attrs[elemCur.ModelAttr] != null && itemCur.model.Attrs[elemCur.ModelAttr].Value != null && elemCur.ElemType === 'Primitive'  && elemCur.Format === 'File' ? 
                                            <a href={"/files/"+JSON.parse(itemCur.model.Attrs[elemCur.ModelAttr].Value).uuid} download
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    e.nativeEvent.stopImmediatePropagation();
                                                }} 
                                            >
                                                Download
                                            </a>
                                        :
                                        
                                            <div> { elemCur.Label === 'Address, Email, Phone' ? 
                                                <div>
                                                    <div style={{display: 'flex', justifyContent: 'flex-start', width: '30em'}}>
                                                        <Typography variant="subtitle1" style={{minWidth: '20%'}}>
                                                            {'Address:'}
                                                        </Typography>
                                                        <TextField style={{width: '70%'}}
                                                            key={1}
                                                            id={'ID_Address'} 
                                                            fullwidth={"true"}
                                                            disabled={true}
                                                            defaultValue={itemCur.model.Attrs['Address'].Value != null ? itemCur.model.Attrs['Address'].Value + (itemCur.model.Attrs['Address2'] != null && itemCur.model.Attrs['Address2'].Value != null ? itemCur.model.Attrs['Address2'].Value : '' )  : ''}
                                                            className={classes.textField}
                                                        />
                                                    </div>
                                                    <div style={{display: 'flex', justifyContent: 'flex-start', width: '30em'}}>
                                                        <Typography variant="subtitle1" style={{minWidth: '20%'}}>
                                                            {''}
                                                        </Typography>
                                                        <TextField style={{width: '70%'}}
                                                            key={2}
                                                            id={'ID_CityStateZip'} 
                                                            fullwidth={"true"}
                                                            disabled={true}
                                                            defaultValue={itemCur.model.Attrs['CityStateZip'].Value != null ? itemCur.model.Attrs['CityStateZip'].Value : ''}
                                                            className={classes.textField}
                                                        />
                                                    </div>
                                                    <div style={{display: 'flex', justifyContent: 'flex-start', width: '30em'}}>
                                                        <Typography variant="subtitle1" style={{minWidth: '20%'}}>
                                                            {'Email:'}
                                                        </Typography>
                                                        <TextField style={{width: '70%'}}
                                                            key={3}
                                                            id={'ID_Email'} 
                                                            fullwidth={"true"}
                                                            disabled={true}
                                                            defaultValue={itemCur.model.Attrs['Email'].Value != null ? itemCur.model.Attrs['Email'].Value : ''}
                                                            className={classes.textField}
                                                        />
                                                    </div>
                                                    <div style={{display: 'flex', justifyContent: 'flex-start', width: '30em'}}>
                                                        <Typography variant="subtitle1" style={{minWidth: '20%'}}>
                                                            {'Phone:'}
                                                        </Typography>
                                                        <TextField style={{width: '70%'}}
                                                            key={4}
                                                            id={'ID_Phone'} 
                                                            fullwidth={"true"}
                                                            disabled={true}
                                                            defaultValue={itemCur.model.Attrs['Phone'].Value != null ? itemCur.model.Attrs['Phone'].Value : ''}
                                                            className={classes.textField}
                                                        />
                                                    </div>
                                                </div>
                                        
                                            :
                                                <span> { elemCur.Label === 'Distribution' && props.ViewChild.Items != null && props.ViewChild.Items[itemCur.key] != null ? 
                                                    <span>{calculatedValue(elemCur, props.ViewChild.Items[itemCur.key], itemCur)}</span>
                                                :
                                                    <span> { elemCur.Label.substr(0,4) === 'Fund' && itemCur.model.Attrs[elemCur.ModelAttr] != null && 
                                                            DefaultSelections.find(cur => cur.Id === itemCur.model.Attrs[elemCur.ModelAttr].Value).Value != null ? 
                                                        <span>{DefaultSelections.find(cur => cur.Id === itemCur.model.Attrs[elemCur.ModelAttr].Value).Value}</span>
                                                    :
                                                        <span> { itemCur.model.Attrs[elemCur.ModelAttr] != null && (elemCur.ElemType === 'Primitive' || elemCur.ElemType === 'Reference') ? 
                                                            <span>{maskValue(elemCur, itemCur.model.Attrs[elemCur.ModelAttr].Value)}</span>
                                                        :
                                                            <span> { itemCur.model.Attrs[elemCur.ModelAttr] != null && itemCur.model.Attrs[elemCur.ModelAttr].Type === 'E' && props.ViewChild.Items != null && props.ViewChild.Items[itemCur.Key] != null ? 
                                                                <pre>{/*flattenEmbedded(elemCur, props.ViewChild.Items[itemCur.key], itemCur)*/}</pre>
                                                            :
                                                                ''
                                                            } </span> 
                                                        } </span>
                                                    } </span>
                                                } </span>
                                            } </div>
                                        }
                                    </TableCell>
                                )
                            }  
                            </TableRow>
                        )}
                        </TableBody>
                    </Table>
                </TableContainer>

            {window.junk != null ? window.junk : ''}
            
            </div>
        :
            <div>
            </div>
            
        } </div>
    )
}

export default Child;

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  rootList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inlineList: {
    display: 'inline',
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },  
  table: {
    minWidth: 350,
  },
  tableHeader: {
    fontWeight: 'bold',
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const maskValue = (spec, data) => {
    let retVal = '';
    if (spec.Format != null && spec.Format === 'Mask' && spec.Mask != null && spec.Mask.Type != null) {
        let amt;
        switch(spec.Mask.Type) {
            case 'Mon YYYY':
                let date1 = new Date(data.substr(0,4)+'-'+data.substr(4,2)+'-'+data.substr(6,2));
                let date2 = date1.toString();
                retVal = date2.substr(4,4) + date2.substr(11,4);
                break;
            case 'Currency US':
                amt = parseFloat(data);
                retVal = '$'+ amt.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                break;
            case 'Number':
                amt = parseFloat(data);
                retVal = amt.toFixed(spec.Mask.Decimals).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                break;
            default:
                break;
        }
    } else {
        retVal = data;
    }
    return retVal;
}

const flattenEmbedded = (elemSpec, itemBase, modelItem) => {
    let retVal = '';
    if (itemBase.RowView != null) {
        let rowViewElem = itemBase.RowView.Elems.find(elemCur => elemCur.SpecElem.Label === elemSpec.Label);
        if (rowViewElem.ExtensionPicked != null) {
            retVal += 'Type: ';
            retVal += rowViewElem.ExtensionPicked;
            retVal += '\n';
            let extensionPickedDetail = rowViewElem.Elems.find(elemSubCur => elemSubCur.SpecElem.ModelAttr === rowViewElem.ExtensionPicked);
            extensionPickedDetail.Elems.forEach(elemSubSubCur => {
                if (elemSubSubCur.Value != null) {
                    retVal += elemSubSubCur.LabelFlattened;
                    retVal += ': ';
                    retVal += elemSubSubCur.Value;
                    retVal += '\n';
                } else {
                    if (elemSubSubCur.ReferencePath != null && elemSubSubCur.ReferencePath.length === 1) {
                        retVal += elemSubSubCur.LabelFlattened;
                        retVal += ': ';
                        retVal += elemSubSubCur.ReferencePath[0].Value;
                        retVal += '\n';
                    }
                }
            });
            
        }
        //modelItem.Attrs[elemSpec.ModelAttr].ValueTemp = retVal;
    }
    return retVal;
}

//
// Dates for month rollover and Fund interest rates here:
//
const calculatedValue = (elemSpec, itemBase, modelItem) => {
    let retVal = '';
    if (modelItem != null && modelItem.model != null) {
        let begPeriodQtr = new Date('7/1/2024');
        let begPeriodMonth = new Date('9/1/2024');
        let endPeriod = new Date('10/1/2024');
        let daysInMonth = 30.0;  
        let daysInQtr = 92.0;  
        let daysInvested = 0.0;
        let payment = 0.0;
        
        let fundAttr = modelItem.model.Attrs['Fund'];
        let fund = parseInt(fundAttr.Value.substr(0,3));
        let admitDateAttr = modelItem.model.Attrs['AdmitDate']; // itemBase.RowView.Elems.find(elemCur => elemCur.SpecElem.ModelAttr === 'AdmitDate');
        let admitDate = new Date(admitDateAttr.Value);
        let cap = parseFloat((modelItem.model.Attrs['EquityAmount']).Value);
        let premAttr = modelItem.model.Attrs['PremiumAmount']; //itemBase.RowView.Elems.find(elemCur => elemCur.SpecElem.ModelAttr === 'PremiumAmount');
        let prem = premAttr != null && premAttr.Value != null && premAttr.Value > '' ? parseFloat(premAttr.Value) : 0.0;
        let percent = fund === 101 ? 6.0 : fund === 103 ? 8.0 : fund === 104 ? 7.0 : fund === 105 ? 6.75 : fund === 106 ? 6.6  : fund === 107 ? 6.75  : 
            fund === 108 ? 6.4 : fund === 109 ? 9.0 : fund === 110 ? 6.75 : fund === 111 ? 6.4 : fund === 112 ? 6.4 : fund === 113 ? 6.5 : 
				fund === 114 ? 5.50 : fund === 115 ? 6.25 : fund === 116 ? 6.30 : 
                fund === 117 ? 6.00 : fund === 118 ? 6.00 : fund === 119 ? 6.00 : fund === 120 ? 5.75 : fund === 121 ? 6.00 : fund === 122 ? 5.75 : 
                fund === 123 ? 6.00 : fund === 124 ? 6.00 : fund === 125 ? 5.75 : fund === 126 ? 5.75 : fund === 127 ? 5.25 : fund === 128 ? 0.0 : 
                fund === 129 ? 5.0 : fund === 130 ? 5.75 : fund === 131 ? 10.00 : fund === 132 ? 5.25 : fund === 133 ? 5.25 : fund === 135 ? 5.5 : fund === 136 ? 10.0 : 
                fund === 137 ? 5.75 : fund === 138 ? 5.25 : fund === 139 ? 12.00 : fund === 1 ? 15.00 : 0;

        if (fund === 101) {
            if (admitDate >begPeriodQtr) {
                daysInvested = parseFloat((endPeriod - admitDate)/(1000 * 60 * 60 * 24));
                daysInvested = parseInt(daysInvested + 0.1);
                payment = (cap+prem) * (percent / 100.0) * (daysInvested / 365.0);
                //comment = 'Prorated days:' + daysInvested;
            } else {
                payment = (cap+prem) * (percent / 100.0) * (daysInQtr / 365.0);
            }
        } else if (fund === 103) {
            if (admitDate >begPeriodMonth) {
                daysInvested = parseFloat( (endPeriod - admitDate)/(1000 * 60 * 60 * 24));
                daysInvested = parseInt(daysInvested + 0.1);
                payment = (cap+prem) * (percent / 100.0) * (daysInvested / 365.0);
                //comment = 'Prorated days:' + daysInvested;
            } else {
                payment = (cap+prem) * (percent / 100.0) * (daysInMonth/ 365.0);
            }
        } else {
            if (admitDate >begPeriodMonth) {
                daysInvested = parseFloat( (endPeriod - admitDate)/(1000 * 60 * 60 * 24));
                daysInvested = parseInt(daysInvested + 0.1);
                payment = (cap+prem) * (percent / 100.0) * (daysInvested/daysInMonth) * (1.0 / 12.0);
                //comment = 'Prorated days:' + daysInvested;
            } else {
                payment = (cap+prem) * (percent / 100.0) * (1.0 / 12.0);
            }
        }

        
        let acctNumCur = modelItem.model.Attrs["AccountNumber"].Value;
        //let qt4found = null; 
        let qt4found = q4spec.find(cur => cur.Account === acctNumCur);
        if (qt4found != null) {
            payment = qt4found.Dist;
        }
        
        retVal = payment.toFixed(2);
        
        if (modelItem.model.Attrs['Distribution'] == null) {
            modelItem.model.Attrs['Distribution'] = {ValueTemp: ''};
        }
        let distributionElem = modelItem.model.Attrs['Distribution']; //itemBase.RowView.Elems.find(elemCur => elemCur.SpecElem.Label === 'Distribution');
        distributionElem.ValueTemp = retVal;
    }
    return retVal;
}

const CSVContent = (props, rowsVisible) => {
    let retVal = '';
    props.ViewChild.viewSpec.Table.Elems.forEach((elemCur, elemIndex) => {
        retVal += JSON.stringify(elemCur.Label);
        retVal += ',';
    });
    retVal = retVal.slice(0, -1);
    retVal += '\r\n';
    rowsVisible.forEach(itemCur => {
        props.ViewChild.viewSpec.Table.Elems.forEach((elemCur, elemIndex) => {
            if (itemCur.model.Attrs != null && itemCur.model.Attrs[elemCur.ModelAttr] != null && 
                    (itemCur.model.Attrs[elemCur.ModelAttr].Type === 'P' || itemCur.model.Attrs[elemCur.ModelAttr].Type === 'R') && 
                    itemCur.model.Attrs[elemCur.ModelAttr].Value != null && elemCur.Label !== 'Distribution') {
                let rawValue = JSON.stringify(itemCur.model.Attrs[elemCur.ModelAttr].Value.toString());
                let newValue;
                if (rawValue.length > 0 && rawValue[0] !== '"' && rawValue[0] === '0' ) {
                    newValue = '"' + rawValue + '"';
                } else {
                    newValue = rawValue;
                }
                retVal += newValue;
            } else {
                if (props.ViewChild.Items != null && props.ViewChild.Items[itemCur.key] != null && props.ViewChild.Items[itemCur.key].RowView != null && props.ViewChild.Items[itemCur.key].RowView.Elems != null) {
                    //let rowViewElemCur = props.ViewChild.Items[itemCur.key].RowView.Elems.find(cur => cur.SpecElem.Label === elemCur.Label &&  cur.ValueTemp != null);
                    //let rowViewElemCur = itemCur.model.Attrs['Distribution'];
                    if (itemCur.model.Attrs[elemCur.Label] != null) {
                        retVal += itemCur.model.Attrs[elemCur.Label].ValueTemp;
                    } else {
                        retVal += '';
                    }
                } else {
                    retVal += '';
                }
            }
            retVal += ',';
        })
        retVal = retVal.slice(0, -1);
        retVal += '\r\n';
    });
    return encodeURIComponent(retVal);
}

//
// NACHA File date here:
//
const NachaContent = (props, fund, fundIndex, accounts) => {
    let retVal = "";
    var batch;
    var user;
    var effectiveEntryDate = '241015';  //'240220'  '240215'
    var fileCreationDateForChase = '240912';  //'240220'  '240215'
    
    let destinationRouting = '122105320';
    let destinationName = 'NB Arizona';
    let immediateOrigin = '1861470447';
    let traceTop8 = null;
    
    if (fund.model.Attrs.Id.Value >= '101' ) {
        destinationRouting ='122105980';
        destinationName = 'WA Bank';
        immediateOrigin = ' 122105980';
        traceTop8 = 12210598;
    }
    if (fund.model.Attrs.Id.Value === '001' ) {
        destinationRouting ='021000021';
        destinationName = 'JPMORGAN CHASE';
        immediateOrigin = ' 000000000';
        traceTop8 = 2100002;
    }

/*
let newDate = new Date();
let newDateString = newDate.toString()
let timeString = newDateString.substring(16,18) + newDateString.substring(19,21)
*/
    let timeString = (parseInt(fundIndex/60)).toString().padStart(2, '0') + (fundIndex % 60).toString().padStart(2, '0');


    let fileCreationDate = effectiveEntryDate;
	if (fund.model.Attrs.Id.Value === '001') {
		fileCreationDate  = fileCreationDateForChase;
	}
    var nacha = Nacha({
        destinationRouting: destinationRouting,
        destinationName: destinationName,
        immediateOrigin: immediateOrigin,
        immediateOriginName: 'IHC',
        companyName: 'IHC',
        //companyIdentification: '1861470447',
        fileCreationDate: fileCreationDate,
        fileCreationTime: timeString, // '1304',
        fileId: 'A'
    });
    batch = nacha.batch({
        companyName: fund.model.Attrs.Short.Value, //'RE FUND',
        effectiveEntryDate: effectiveEntryDate,
        originatingFinancialInstitution: immediateOrigin,//'1861470447',
        originatingFIIdentification: destinationRouting.substr(0,8),//'12210532',
        companyIdentification: fund.model.Attrs.CompanyId.Value, //'1861470447',
        companyEntryDescription: 'IHC DISTR'
    });    
    
    accounts.sort((a, b) => {
        let val_a = a.model.Attrs.Title.Value.toUpperCase();
        let val_b = b.model.Attrs.Title.Value.toUpperCase();
        if (val_a < val_b) {
            return -1;
        } else if (val_a > val_b) {
            return 1;
        } else {
            return 0;
        }
    });
    
    accounts.forEach((curAccount, index) => {
        
        if (curAccount.model.Attrs.Fund.Value === fund.model.Attrs.Id.Value ) {
            let cur = curAccount.model;
            let routing = curAccount.model.Attrs.DFI_NUM_WITH_CHECK.Value.toString();
            let account = curAccount.model.Attrs.DFI_ACC_NUM.Value.toString();
            let payment = parseFloat(calculatedValue(null, null, curAccount));
            let name = curAccount.model.Attrs.Title.Value;

            if (routing.length === 8) {
                routing = '0' + routing;
            } else {
                if (routing.length === 9 && routing[0] === ' ') {
                    routing = '0' + routing.substr(1,8);
                }
            }
            if (routing > ' ' && account > ' ' && payment > 0.001) {
                user = {
                    routing: routing,
                    account: account,
                    amount: payment,
                    user_id: '',
                    name: name.substr(0,22)
                };
                if (fund.model.Attrs.Id.Value === '001') {
					user.user_id  = curAccount.key.replace('-', '');
				}
                
                //console.log(user);
                batch.entry({
                    routing: user.routing,
                    account: user.account,
                    amount: user.amount,
                    individualId: user.user_id,
                    individualName: user.name || user.user_id,
                    traceTop8:  traceTop8
                });

            }
        }
    })

    if (nachaReady === true) { // && nacha.header.prototype.serialize != null) {
        let x = 9;
        retVal = nacha.end();
    }
    
    return encodeURIComponent(retVal);
}

function Nacha(opt) {
    if (!(this instanceof Nacha)) {
        return new Nacha(opt);
    }

    var self = this;

    assert(valid_aba(opt.destinationRouting));

    // number of records including the header and footer must be a multiple of this
    self._blocking_factor = 10;

    self._batches = [];

    var rec = self.header = Record(nachaRecordType["FileHeader"])(); //record.FileHeader();

    rec.immediateDestinationName = opt.destinationName;
    rec.immediateDestination = opt.destinationRouting;
    //rec.immediateOrigin = opt.companyIdentification;
    rec.immediateOrigin = opt.immediateOrigin;
    rec.immediateOriginName = opt.companyName;
    rec.fileCreationDate = opt.fileCreationDate;
    rec.fileCreationTime = opt.fileCreationTime;
    rec.fileId = opt.fileId;
    

    let d = 0;

}

// new batch
Nacha.prototype.batch = function(opt) {
    var self = this;

    var batch = Batch(opt);
    self._batches.push(batch);
    return batch;
};

Nacha.prototype.end = function() {
    var self = this;
    var out = '';

    var records = 0;
    function write(record) {
        out += record.serialize() + '\n';
        
        if (out.length !== 94) {
            let r = 7;
        }
        records++;
    }

    write(self.header);

    // write all batches
    var entryCount = 0;
    var entryHash = 0;
    var debits = 0;
    var credits = 0;
    self._batches.forEach(function(batch, idx) {
        var batchNumber = idx + 1;
        var header = batch.header();
        header.batchNumber = batchNumber;

        write(header);

        batch.entries.forEach(function(entry) {
            entryCount++;
            write(entry);
        });
        var control = batch.control();
        control.batchNumber = batchNumber;

        entryHash += control.entryHash - 0;
//console.log(entryHash)
        credits += parseInt(control.creditAmount);
        
//console.log("\n\n credits:", credits, "  control.creditAmount: ", control.creditAmount);
        
        debits += parseInt(control.debitAmount);
        write(control);
    });

    // make file control record

    var blockCount = Math.ceil(records / self._blocking_factor);

    var control = Record(nachaRecordType["FileControl"])(); // record.FileControl();

    control.batchCount = self._batches.length;
    control.blockCount = blockCount;
    control.entryCount = entryCount;
    control.entryHash = entryHash;
    control.totalDebitAmount = debits;
    control.totalCreditAmount = credits;
    
    if (control.entryHash.length > 10) {
        control.entryHash = control.entryHash.slice(control.entryHash.length-10);
    }

//console.log(control)

    write(control);

    // pad to blocking factor if needed using rows of 9's
    var need_pad = records % self._blocking_factor;
    if (need_pad > 0) {
        need_pad = self._blocking_factor - need_pad;
    }

    for (var i=0 ; i<need_pad ; ++i) {
        out += Array(95).join('9') + '\n';
        records++;
    }

    assert(records % self._blocking_factor === 0);
    return out;
};

var Batch = function(details) {
    if (!(this instanceof Batch)) {
        return new Batch(details);
    }

    var self = this;

    self.details = details;
    self.entries = [];

    self._entry_hash = 0;
    self._debit = 0;
    self._credit = 0;
};

// add an entry to the batch
Batch.prototype.entry = function(opt) {
    var self = this;

    var routing = opt.routing;
    var transit = routing.slice(0, 8);
    var check = routing[8];

    if (!check) {
        throw new Error('no check digit ' + JSON.stringify(opt));
    }

    assert(valid_aba(routing), JSON.stringify(opt));

    // offset for cents and truncate any leftover
    var amount = Number(opt.amount * 100).toFixed(0);

    // check for amount field overflow
    assert(amount <= 9999999999, 'amount is too large');

    var rec = Record(nachaRecordType["EntryDetail"])(); //record.EntryDetail();

    // transaction code is whatever you are doing to the beneficiaries account

    rec.transactionCode = '22';
    
    if (    (opt.account === '1234016437' && opt.routing === '122037760')
        ||  (opt.account === '1601715' && opt.routing === '021201383')
        ||  (opt.account === '6100077796' && opt.routing === '211075086')
        ||  (opt.account === '1044833' && opt.routing === '075905033')
        ||  (opt.account === '4039532451' && opt.routing === '122106015')
        ||  (opt.account === '151706186773' && opt.routing === '122105155')
        ||  (opt.account === '0350271007' && opt.routing === '302170463')
        ||  (opt.account === '5797827598' && opt.routing === '102003154')
        ||  (opt.account === '4200018798' && opt.routing === '114021933')
        ||  (opt.account === '4039532451' && opt.routing === '122106015')
        ||  (opt.account === '000003882990667' && opt.routing === '021000021')
        ||  (opt.account === '6033844176' && opt.routing === '125008547')
        ||  (opt.account === '7008026551' && opt.routing === '031100649')
        ||  (opt.account === '00006800873936' && opt.routing === '026013673')
        ||  (opt.account === '7008026551' && opt.routing === '031100649')
        ||  (opt.account === '1368160' && opt.routing === '323075880')
        //||  (opt.account === '38105720760' && opt.routing === '021200339')
        ||  (opt.account === '0215117867' && opt.routing === '314074269')
        ||  (opt.account === '5007930588' && opt.routing === '021213591')
        ||  (opt.account === '8803096275' && opt.routing === '121143273')
        ||  (opt.account === '13017222' && opt.routing === '031176110')
        ||  (opt.account === '1583248870' && opt.routing === '071925567')

        ||  (opt.account === '6681634309' && opt.routing === '121042882')
        ||  (opt.account === '218529' && opt.routing === '102101250')
        ||  (opt.account === '4476475' && opt.routing === '322281507')
        ||  (opt.account === '8289676937' && opt.routing === '102000076')
        ||  (opt.account === '0110629168' && opt.routing === '122000496')
        ||  (opt.account === '3016460064' && opt.routing === '322271627')
        ||  (opt.account === '42013094620' && opt.routing === '322271724')
        ||  (opt.account === '13623450' && opt.routing === '021000089')
        ||  (opt.account === '7028476835' && opt.routing === '031100649')
        ||  (opt.account === '7028476792' && opt.routing === '031100649')
        //||  (opt.account === '9200283536' && opt.routing === '301171353')

        ||  (opt.account === '05000025803' && opt.routing === '124084834')
        ||  (opt.account === '0703565895' && opt.routing === '122105278')
        ||  (opt.account === '1851022300' && opt.routing === '322271627')
        //||  (opt.account === '0000002232' && opt.routing === '082902757')
        ||  (opt.account === '2188754937' && opt.routing === '124003116')
        ||  (opt.account === '20152605320' && opt.routing === '104000016')
        ||  (opt.account === '3967328353' && opt.routing === '122100024')
        ||  (opt.account === '2203279431' && opt.routing === '124003116')

        ||  (opt.account === '008120073' && opt.routing === '122000496')
        ||  (opt.account === '01000060433301' && opt.routing === '321177968')

        // ||  (opt.account === '890598860' && opt.routing === '322271627')    127-000097
        // ||  (opt.account === '96301882' && opt.routing === '122243334')     133-000012
        
        // 131-000011
        ||  (opt.account === '867119101' && opt.routing === '322078341')

        // 001-000024
        ||  (opt.account === '2827747698' && opt.routing === '113007835')
        
        // 131-000394
        ||  (opt.account === '3805983302' && opt.routing === '267084131')
        
        ) {
        rec.transactionCode = '32';
    }
    
    let traceBase = 0;
    if (opt.traceTop8 != null) {
        traceBase = opt.traceTop8 * 10000000;
    }
        
    rec.receivingDFIIdentification = transit;
    rec.checkDigit = check;
    rec.receivingDFIAccountNumber = opt.account;
    rec.amount = amount.toString();
    rec.individualIdentificationNumber = opt.individualId;
    rec.individualName = opt.individualName;
    
    //rec.traceNumber = self.entries.length + 1;
    rec.traceNumber = traceBase + self.entries.length + 1;

    self.entries.push(rec);

    self._entry_hash += (transit - 0);
    //self._entry_hash += parseInt(transit);
    //if (this.details.companyName === 'IHC IF5') {
	//	console.log(transit, self._entry_hash, this.details.companyName)
	//}
    

    // TODO track debits and credits
    self._credit += (amount.toString() - 0);
};

Batch.prototype.header = function() {
    var self = this;
    var rec = Record(nachaRecordType["BatchHeader"])(); //record.BatchHeader();

    var opt = this.details;

    rec.serviceClassCode = 220;
    rec.companyName = opt.companyName;
    // fein
    rec.companyIdentification = opt.companyIdentification;
    rec.standardEntryClassCode = 'PPD';
    rec.companyEntryDescription = opt.companyEntryDescription;
    rec.effectiveEntryDate = opt.effectiveEntryDate;
    // our routing
    rec.originatingFinancialInstitution = opt.originatingFIIdentification;
    rec.batchNumber = 0;

    return rec;
};

// get the control record for the batch
Batch.prototype.control = function() {
    var self = this;

    var rec = Record(nachaRecordType["BatchControl"])(); //record.BatchControl();

    var opt = this.details;

    rec.serviceClassCode = '220';
    rec.entryCount = self.entries.length;
        
    rec.entryHash = self._entry_hash % 10000000000; // self._entry_hash;
        
    //console.log(rec.entryHash)
    rec.debitAmount = self._debit;
    rec.creditAmount = self._credit;
    // fein
    rec.companyIdentification = opt.companyIdentification;
    // routing
    rec.originatingDFIIdentification = opt.originatingFIIdentification;

    // rec.batchNumber is set by whomever adds this record to a file
    // Batch only manages information for itself
    return rec;
};

const valid_aba = (routing) => {
    if (routing.length !== 9 || !/\d{9}/.test(routing)) {
        return false;
    }

    var d = routing.split('').map(Number);
    var sum =
        3 * (d[0] + d[3] + d[6]) +
        7 * (d[1] + d[4] + d[7]) +
        1 * (d[2] + d[5] + d[8]);

    return sum % 10 === 0;
}

var Record = function(fields) {

    var Obj = function() {
        if (!(this instanceof Obj)) {
            return new Obj();
        }

        var self = this;

        self.fields = fields.map(Field);
        self.fields.forEach(function(field) {
            Object.defineProperty(self, field.name, {
                get: function() {
                    return field.get();
                },
                set: function(val) {
                    field.set(val);
                }
            });
        });
    };

    Obj.prototype.serialize = function() {
        var self = this;

        var out = '';
        self.fields.forEach(function(field) {
            out += field.serialize();

        });

        if (out.length !== 94) {
            let r = 7;
        }

        //assert.equal(out.length, 94, 'invalid record length');
        return out;
    };

    return Obj;
}

const nachaRecordType =
{
  "FileHeader": [
    {
      "name": "type",
      "size": 1,
      "format": "/1/",
      "default": "1"
    },
    {
      "name": "priorityCode",
      "size": 2,
      "format": "/\\d\\d/",
      "default": "01"
    },
    {
      "name": "_space_",
      "size": 1,
      "format": "/ /",
      "default": " "
    },
    {
      "name": "immediateDestination",
      "size": 9,
      "format": "/\\d{9}/"
    },
    {
      "name": "immediateOrigin",
      "size": 10,
      "format": "Alpha"
    },
    {
      "name": "fileCreationDate",
      "size": 6,
      "format": "/\\d{6}/"
    },
    {
      "name": "fileCreationTime",
      "size": 4,
      "format": "/\\d{4}/",
      "default": ""
    },
    {
      "name": "fileId",
      "size": 1,
      "format": "/[A-Z0-9]/",
      "default": ""
    },
    {
      "name": "recordSize",
      "size": 3,
      "format": "/\\d{3}/",
      "default": "094"
    },
    {
      "name": "blockingFactor",
      "size": 2,
      "format": "/\\d{2}/",
      "default": "10"
    },
    {
      "name": "formatCode",
      "size": 1,
      "format": "/1/",
      "default": "1"
    },
    {
      "name": "immediateDestinationName",
      "size": 23,
      "format": "Alpha",
      "default": ""
    },
    {
      "name": "immediateOriginName",
      "size": 23,
      "format": "Alpha",
      "default": ""
    },
    {
      "name": "referenceCode",
      "size": 8,
      "format": "Alpha",
      "default": ""
    }
  ],
  "BatchHeader": [
    {
      "name": "type",
      "size": 1,
      "format": "/5/",
      "default": "5"
    },
    {
      "name": "serviceClassCode",
      "size": 3,
      "format": "Numeric"
    },
    {
      "name": "companyName",
      "size": 16,
      "format": "Alpha"
    },
    {
      "name": "companyDiscretionaryData",
      "size": 20,
      "format": "Alpha",
      "default": ""
    },
    {
      "name": "companyIdentification",
      "size": 10,
      "format": "Numeric"
    },
    {
      "name": "standardEntryClassCode",
      "size": 3,
      "format": "/PPD|CCD"
    },
    {
      "name": "companyEntryDescription",
      "size": 10,
      "format": "Alpha"
    },
    {
      "name": "companyDescriptiveDate",
      "size": 6,
      "format": "Alpha",
      "default": ""
    },
    {
      "name": "effectiveEntryDate",
      "size": 6,
      "format": "Numeric"
    },
    {
      "name": "settlementDate",
      "size": 3,
      "format": "/[ ]{3}",
      "default": "   "
    },
    {
      "name": "originatorStatusCode",
      "size": 1,
      "format": "Numeric",
      "default": "1"
    },
    {
      "name": "originatingFinancialInstitution",
      "size": 8,
      "format": "Numeric"
    },
    {
      "name": "batchNumber",
      "size": 7,
      "format": "Numeric"
    }
  ],
  "EntryDetail": [
    {
      "name": "type",
      "size": 1,
      "format": "/6/",
      "default": "6"
    },
    {
      "name": "transactionCode",
      "size": 2,
      "format": "Numeric"
    },
    {
      "name": "receivingDFIIdentification",
      "size": 8,
      "format": "Numeric"
    },
    {
      "name": "checkDigit",
      "size": 1,
      "format": "Numeric"
    },
    {
      "name": "receivingDFIAccountNumber",
      "size": 17,
      "format": "Alpha"
    },
    {
      "name": "amount",
      "size": 10,
      "format": "Numeric"
    },
    {
      "name": "individualIdentificationNumber",
      "size": 15,
      "format": "Alpha"
    },
    {
      "name": "individualName",
      "size": 22,
      "format": "Alpha"
    },
    {
      "name": "discretionaryData",
      "size": 2,
      "format": "Alpha",
      "default": "  "
    },
    {
      "name": "addendaRecordIdentifier",
      "size": 1,
      "format": "/1|0/",
      "default": "0"
    },
    {
      "name": "traceNumber",
      "size": 15,
      "format": "Numeric"
    }
  ],
  "BatchControl": [
    {
      "name": "type",
      "size": 1,
      "format": "/8/",
      "default": "8"
    },
    {
      "name": "serviceClassCode",
      "size": 3,
      "format": "Numeric"
    },
    {
      "name": "entryCount",
      "size": 6,
      "format": "Numeric"
    },
    {
      "name": "entryHash",
      "size": 10,
      "format": "Numeric"
    },
    {
      "name": "debitAmount",
      "size": 12,
      "format": "Numeric"
    },
    {
      "name": "creditAmount",
      "size": 12,
      "format": "Numeric"
    },
    {
      "name": "companyIdentification",
      "size": 10,
      "format": "Numeric"
    },
    {
      "name": "messageAuthenticationCode",
      "size": 19,
      "format": "Alpha",
      "default": ""
    },
    {
      "name": "reserved",
      "size": 6,
      "format": "/[ ]{6}",
      "default": ""
    },
    {
      "name": "originatingDFIIdentification",
      "size": 8,
      "format": "Numeric"
    },
    {
      "name": "batchNumber",
      "size": 7,
      "format": "Numeric"
    }
  ],
  "FileControl": [
    {
      "name": "type",
      "size": 1,
      "format": "/9/",
      "default": "9"
    },
    {
      "name": "batchCount",
      "size": 6,
      "format": "Numeric"
    },
    {
      "name": "blockCount",
      "size": 6,
      "format": "Numeric"
    },
    {
      "name": "entryCount",
      "size": 8,
      "format": "Numeric"
    },
    {
      "name": "entryHash",
      "size": 10,
      "format": "Numeric"
    },
    {
      "name": "totalDebitAmount",
      "size": 12,
      "format": "Amount"
    },
    {
      "name": "totalCreditAmount",
      "size": 12,
      "format": "Amount"
    },
    {
      "name": "reserved",
      "size": 39,
      "format": "/[ ]{39}/",
      "default": ""
    }
  ]
};


var Field = function(details) {
    if (!(this instanceof Field)) {
        return new Field(details);
    }

    var self = this;
    self.name = details.name;
    self.size = details.size;
    self.val = details.default;

    // regex format check
    if (details.format[0] === '/') {
        self.formatter = new RegExp(details.format.slice(1, -1));
        return;
    }

    var formatter = /.*/;

    switch (details.format) {
    case 'Alpha':
        formatter = /[a-zA-Z0-9]*/;
        break;
    case 'Numeric':
    case 'Amount':
        self._numeric = true;
        formatter = new RegExp('[0-9]{0,' + details.size + '}');
        break;
    default:
        throw new Error('unknown format: ' + details.format);
    }

    self.formatter = formatter;
};

Field.prototype.set = function(val) {
    if (!this.formatter.test(val)) {
        throw new Error('invalid value `' + val + '` for field: `' + this.name + '` expected: ' + this.formatter.toString());
    }

    this.val = '' + val;
};

Field.prototype.get = function(val) {
    return this.val;
};

Field.prototype.serialize = function() {
    var self = this;
    var val = this.val;

    if (val === undefined) {
        throw new Error('undefined value for field: ' + this.name);
    }

    var out = '' + val;

    if (out.length > self.size) {
        let s = 8;
        out = '' + val.substr(0, self.size);
    }
    assert(out.length <= self.size, 'value `' + out + '` is too big for field: ' + this.name);

    var pads = Array(self.size - out.length + 1);

    if (self._numeric) {
        return pads.join('0') + out;
    }

    return out + pads.join(' ');
}

let q4spec = 
[
 {
   "Account": "116-000098",
   "Dist": 176.72
 }
 ,
 {
   "Account": "130-000081",
   "Dist": 2961.92
 }
 ,
 {
   "Account": "130-000092",
   "Dist": 2016.13
 }
 ,
 {
   "Account": "135-000009",
   "Dist": 2217.74
 }
 ,
 {
   "Account": "135-000046",
   "Dist": 3405.02
 }
 ,
 {
   "Account": "131-000673",
   "Dist": 527.70
 }
 ,
 {
   "Account": "131-000289",
   "Dist": 1479.16
 }
 ,
 {
   "Account": "138-000082",
   "Dist": 490.46
 }
 ,
 {
   "Account": "131-001136",
   "Dist": 0.00
 }

 /*

 ,
 {
   "Account": "112-000002",
   "Dist": 1144.35
 },
 {
   "Account": "112-000003",
   "Dist": 852.03
 },
 {
   "Account": "112-000004",
   "Dist": 829.02
 },
 {
   "Account": "112-000049",
   "Dist": 1962.61
 },
 {
   "Account": "112-000050",
   "Dist": 2110.06
 },
 {
   "Account": "112-000051",
   "Dist": 1439.6
 },
 {
   "Account": "112-000052",
   "Dist": 381.45
 },
 {
   "Account": "112-000053",
   "Dist": 6117.4
 },
 {
   "Account": "112-000054",
   "Dist": 2034.41
 },
 {
   "Account": "112-000055",
   "Dist": 282.96
 }
*/
];
